import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, ConfigProvider, Divider, Form, Row, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import CustomInputPassword from "../../components/shared/form/reactHookForm/CustomInputPassword";
import { changePassword, clearOperationResultAction } from "../../store/user/general/userActions";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";

// Constants
const layout = { labelCol: { span: 10 }, wrapperCol: { span: 14 } };

const changePasswordSchema = yup.object().shape({
    currentPassword: yup.string().required("Please enter your current password."),
    newPassword: yup
        .string()
        .required("Please enter a new password.")
        .notOneOf(
            [yup.ref("currentPassword"), null],
            "New password cannot be the same as the current password."
        )
        .min(6, "New password must be at least 6 characters.")
        .matches(/[^a-zA-Z0-9]/, "New password must include at least one special character.")
        .matches(/[a-z]/, "New password must include at least one lowercase letter.")
        .matches(/[A-Z]/, "New password must include at least one uppercase letter."),
    newPasswordConfirmation: yup
        .string()
        .required("Please confirm your new password.")
        .oneOf([yup.ref("newPassword"), null], "Passwords do not match."),
});

const ChangePassword = ({ onCloseHandler }) => {
    const dispatch = useDispatch();
    const operationResult = useSelector((state) => state.user.general.operationResult);
    const loading = useSelector((state) => state.user.general.loading);

    const {
        control,
        handleSubmit,
        reset,
        trigger,
        watch,
        formState: { isValid, isDirty, dirtyFields },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
        },
        resolver: yupResolver(changePasswordSchema),
    });

    const currentPassword = watch("currentPassword");
    const newPassword = watch("newPassword");

    // Ensure menu closes before success popup displays
    useEffect(() => {
        if (operationResult) {
            const resultCopy = operationResult;
            dispatch(clearOperationResultAction());
            if (operationResult.Outcome === "Success") {
                onCloseHandler();
            }
            openNotificationOperationResult(resultCopy);
        }
    }, [operationResult, dispatch, reset, onCloseHandler]);

    // Trigger validation for newPasswordConfirmation only if user has interacted with it
    useEffect(() => {
        if (dirtyFields.newPasswordConfirmation) {
            trigger("newPasswordConfirmation");
        }
    }, [newPassword, trigger, dirtyFields.newPasswordConfirmation]);

    // Trigger validation for newPassword when currentPassword changes
    useEffect(() => {
        if (dirtyFields.newPassword) {
            trigger("newPassword");
        }
    }, [currentPassword, trigger, dirtyFields.newPassword]);

    const onSubmitHandler = (data) => {
        const payload = {
            CurrentPassword: data.currentPassword,
            NewPassword: data.newPassword,
        };
        dispatch(changePassword(payload));
    };

    return (
        <Spin spinning={loading} delay={500}>
            <Form
                size="small"
                autoComplete="off"
                name="changePassword"
                onFinish={handleSubmit(onSubmitHandler)}
            >
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: "#78a1cf",
                            borderRadius: 8,
                        },
                    }}
                >
                    <CustomInputPassword
                        required
                        control={control}
                        formLabel="Current Password"
                        name="currentPassword"
                        layout={layout}
                    />
                    <CustomInputPassword
                        required
                        control={control}
                        formLabel="New Password"
                        name="newPassword"
                        layout={layout}
                    />
                    <CustomInputPassword
                        required
                        control={control}
                        formLabel="Confirm New Password"
                        name="newPasswordConfirmation"
                        layout={layout}
                    />
                    <Divider style={{ marginBottom: 12, marginTop: 0 }} />

                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <Button
                                        size="small"
                                        type="primary"
                                        id="saveButton"
                                        htmlType="submit"
                                        disabled={!isValid || !isDirty}
                                    >
                                        Submit
                                    </Button>
                                    <Button onClick={() => onCloseHandler()} type="dashed">
                                        Close
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form.Item>
                </ConfigProvider>
            </Form>
        </Spin>
    );
};

export default ChangePassword;
