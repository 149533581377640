import * as yup from "yup";
import {
  requiredMessage,
  dateRangeRequiredMessage,
  maxMessage,
  lessOrEqualOneYearMessage,
  postalCodesCommaListRegex,
  postalCodesCommaListMessage,
  postalCodesCommaListRegexOrEmptyString,
  mailingLabelFormatRequiredMessage,
  fleetGarageCommaListMessage,
  fleetGarageCommaListRegexOrEmptyString,
} from "../shared/form/formValidatonRules";
import dayjs from "dayjs";
import { renewalListReportTypes } from "./reportParameters";

export const textSummaryReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  ExpiryDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  Keyword: yup.string().max(50, maxMessage),
});

export const bcarRetentionReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  ExpiryDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
});

export const retentionSummaryReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  ExpiryDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  DrillDownSortFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  PolicyStatusActive: yup.boolean().required(requiredMessage),
  PolicyStatusExpired: yup.boolean().required(requiredMessage),
  PolicyStatusCancelled: yup.boolean().required(requiredMessage),
  PolicyStatusRenewed: yup.boolean().required(requiredMessage),
  PolicyStatus: yup
    .boolean()
    .when(
      [
        "PolicyStatusActive",
        "PolicyStatusExpired",
        "PolicyStatusCancelled",
        "PolicyStatusRenewed",
      ],
      {
        is: false,
        then: yup
          .boolean()
          .required("At least one of the options is required."),
      }
    ),
});

export const renewalReportSchema = yup.object().shape({
  AgencyKeys: yup.array().required(requiredMessage).min(1, requiredMessage),
  ExpiryDate: yup
    .array()
    .test(
      "lessThanYear",
      lessOrEqualOneYearMessage,
      (value) => dayjs(value[1]).diff(dayjs(value[0]), "year") === 0
    )
    .required(dateRangeRequiredMessage)
    .nullable(),
  SortGroupFields: yup.array().of(
    yup.object().shape({
      Name: yup.string().required(requiredMessage),
    })
  ),
  PostalCodes: yup
    .string()
    .matches(
      postalCodesCommaListRegexOrEmptyString,
      postalCodesCommaListMessage
    ),
  CustomerConsent: yup
    .boolean()
    .when(["CustomerConsentYes", "CustomerConsentNo", "CustomerConsentBlank"], {
      is: false,
      then: yup.boolean().required("Select at least one consent"),
    }),
  LeasedVehicle: yup.boolean().when(["IncludeLeased", "IncludeNotLeased"], {
    is: false,
    then: yup.boolean().required("Select at least one option"),
  }),
  PolicyStatusActive: yup.boolean().required(requiredMessage),
  PolicyStatusExpired: yup.boolean().required(requiredMessage),
  PolicyStatusCancelled: yup.boolean().required(requiredMessage),
  PolicyStatusRenewed: yup.boolean().required(requiredMessage),
  PolicyStatus: yup
    .boolean()
    .when(
      [
        "PolicyStatusActive",
        "PolicyStatusExpired",
        "PolicyStatusCancelled",
        "PolicyStatusRenewed",
      ],
      {
        is: false,
        then: yup
          .boolean()
          .required("At least one of the options is required."),
      }
    ),
  LabelKey: yup
    .number()
    .nullable()
    .when("ReportType", {
      is: (val) => val == renewalListReportTypes.MAILING_LABELS,
      then: (schema) => schema.required(mailingLabelFormatRequiredMessage),
      otherwise: (schema) => schema.notRequired(),
    }),
  VehicleYearFrom: yup
    .number()
    .nullable()
    .integer("Invalid year")
    .min(1900, "Invalid year")
    .transform((_, val) => (val === Number(val) ? val : null))
    .max(dayjs().add(1, "year").year(), "Invalid year"),

  VehicleYearTo: yup
    .number()
    .nullable()
    .integer("Invalid year")
    .min(1900, "Invalid year")
    .max(dayjs().add(1, "year").year(), "Invalid year")
    .transform((_, val) => (val === Number(val) ? val : null))
    .when("VehicleYearFrom", (VehicleYearFrom, schema) => {
      return schema.test({
        test: (VehicleYearTo) =>
          VehicleYearTo == null ||
          VehicleYearFrom == null ||
          VehicleYearFrom <= VehicleYearTo,
        message: `Must be greater than range start`,
      });
    }),
  TPLLimitFrom: yup
    .number()
    .nullable()
    .integer("Invalid value")
    .min(0, "Invalid value")
    .transform((_, val) => (val === Number(val) ? val : 0)),
  TPLLimitTo: yup
    .number()
    .min(0, "Invalid value")
    .nullable()
    .integer("Invalid value")
    .transform((_, val) => (val === Number(val) ? val : 0))
    .when("TPLLimitFrom", (TPLLimitFrom, schema) => {
      return schema.test({
        test: (TPLLimitTo) => TPLLimitFrom <= TPLLimitTo,
        message: `Invalid range, less than range start`,
      });
    }),
  ColDedFrom: yup
    .number()
    .nullable()
    .integer("Invalid value")
    .min(0, "Invalid value")
    .transform((_, val) => (val === Number(val) ? val : 0)),
  ColDedTo: yup
    .number()
    .min(0, "Invalid value")
    .nullable()
    .integer("Invalid value")
    .transform((_, val) => (val === Number(val) ? val : 0))
    .when("ColDedFrom", (ColDedFrom, schema) => {
      return schema.test({
        test: (ColDedTo) => ColDedFrom <= ColDedTo,
        message: `Invalid range, less than range start`,
      });
    }),
  CompDedFrom: yup
    .number()
    .nullable()
    .integer("Invalid value")
    .min(0, "Invalid value")
    .transform((_, val) => (val === Number(val) ? val : 0)),
  CompDedTo: yup
    .number()
    .min(0, "Invalid value")
    .nullable()
    .integer("Invalid value")
    .transform((_, val) => (val === Number(val) ? val : 0))
    .when("CompDedFrom", (CompDedFrom, schema) => {
      return schema.test({
        test: (CompDedTo) => CompDedFrom <= CompDedTo,
        message: `Invalid range, less than range start`,
      });
    }),
  FleetGaragePolicyNumbers: yup
    .string()
    .matches(
      fleetGarageCommaListRegexOrEmptyString,
      fleetGarageCommaListMessage
    ),
});
