import { FileExcelOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  clearOperationResult,
  listAppLogs,
} from "../../store/appLog/appLogActions";
import PermissionWrapper from "../navigation/PermissionWrapper";
import { exportDataToExcel } from "../shared/exportToExcel";
import { fixedDateRanges } from "../shared/form/fixedDateRanges";
import AdvancedRangePicker from "../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, formatDateTime } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import {
  PreservableStateLink,
  PreservableStatePageWrapper,
  usePreservableNavigate,
} from "../shared/PreservableStatePageWrapper";
import { columnTypes } from "../shared/table/columnTypes";
import { SearchableTable } from "../shared/table/SearchableTable";
import { tableNames } from "../shared/table/tableNames";
import AppLogForm from "./AppLogForm";
import { debounce } from "lodash";

const { Text } = Typography;

const colSize = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
  xxl: 12,
};

const firstLayout = {
  labelCol: {
    xs: null,
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: null,
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const AppLogs = () => {
  return (
    <PreservableStatePageWrapper
      tableNames={[tableNames.AppLogs]}
      onCleanup={() => {}}
    >
      <AppLogsBody />
    </PreservableStatePageWrapper>
  );
};

const initialValues = {
  FixedDateRange: fixedDateRanges.TODAY,
  Timestamp: ["", ""],
  LevelInformation: false,
  LevelWarning: false,
  LevelError: true,
};

const AppLogsBody = () => {
  const data = useSelector((state) => state.appLog.data);
  const loading = useSelector((state) => state.appLog.loading);
  const operationResult = useSelector((state) => state.appLog.operationResult);

  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  const {
    reset,
    control,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { defaultValues, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
  });

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResult())
    );
  }, [operationResult]);

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.AppLogs)
  );

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableData?.extra.currentDataSource,
      [],
      false,
      "AppLogs.csv"
    );
    setIsExporting(false);
  };

  const fetchAppLogs = (model) => {
    dispatch(listAppLogs(model));
  };

  const fetchLogsMemoized = useMemo(
    () => debounce(fetchAppLogs, 1000),
    [dispatch]
  );

  const timestamp = watch("Timestamp");
  const levelInformation = watch("LevelInformation");
  const levelError = watch("LevelError");
  const levelWarning = watch("LevelWarning");

  useEffect(() => {
    if (timestamp && timestamp[0] && timestamp[1]) {
      const levels = [];

      if (levelError) {
        levels.push("Error");
      }

      if (levelWarning) {
        levels.push("Warning");
      }

      if (levelInformation) {
        levels.push("Information");
      }
      const model = {
        Timestamp: timestamp,
        Levels: levels,
      };
      fetchLogsMemoized(model);
    }
  }, [timestamp, levelInformation, levelError, levelWarning]);

  const exportButton = (
    <Button
      size={"small"}
      loading={isExporting}
      onClick={() => exportTableData()}
    >
      <FileExcelOutlined />
      Export
    </Button>
  );

  const toolbarButtons = [exportButton];

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "Timestamp",
      type: columnTypes.DATETIME,
      render: (value, record) => (
        <PreservableStateLink to={`appLogForm/${record.Id}`}>
          {formatDateTime(value)}
        </PreservableStateLink>
      ),
      width: 110,
    },
    {
      title: "Level",
      dataIndex: "Level",
      type: columnTypes.TEXT,
      width: 50,
    },

    {
      title: "Message",
      dataIndex: "Message",
      type: columnTypes.TEXT,
      width: 200,
      ellipsis: true,
    },

    {
      title: "User Key",
      dataIndex: "UserKey",
      type: columnTypes.NUMBER,
      width: 40,
    },
    {
      title: "Data Primary Key",
      dataIndex: "DataPrimaryKey",
      type: columnTypes.NUMBER,
      width: 60,
    },
    {
      title: "Additional Data",
      dataIndex: "AdditionalData",
      type: columnTypes.TEXT,
      width: 120,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "Action",
      type: columnTypes.TEXT,
      width: 120,
      ellipsis: true,
    },
    {
      title: "Source Context",
      dataIndex: "SourceContext",
      type: columnTypes.TEXT,
      width: 100,
      ellipsis: true,
    },
    {
      title: "Duration",
      dataIndex: "Duration",
      type: columnTypes.NUMBER,
      width: 60,
      render: (value) => <Text>{value ? `${value} ms` : "N/A"}</Text>,
    }
  ];

  return (
    <PermissionWrapper entityName={tableNames.AppLogs}>
      <Routes>
        <Route
          path=""
          element={
            <>
              <Form size="small" autoComplete="off">
                <Row>
                  <Col {...colSize}>
                    <AdvancedRangePicker
                      formItem={{
                        layout: firstLayout,
                        required: true,
                        label: "Timestamp",
                      }}
                      control={control}
                      setValue={setValue}
                      allowClear={false}
                      name="Timestamp"
                      format={dateFormat}
                      disableFutureDates={true}
                      afterChange={() => {}}
                    />
                  </Col>
                  <Col {...colSize}>
                    <Form.Item
                      {...firstLayout}
                      label="Level"
                      name="Level"
                      required
                      valuePropName="checked"
                    >
                      <Space direction="horizontal">
                        <Controller
                          control={control}
                          name="LevelError"
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              inputRef={field.ref}
                              name="LevelError"
                              onChange={(e) => {
                                const levelError = e.target.checked;

                                setValue("LevelError", levelError, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }}
                            >
                              Error
                            </Checkbox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="LevelWarning"
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              inputRef={field.ref}
                              name="LevelWarning"
                              onChange={(e) => {
                                const levelWarning = e.target.checked;

                                setValue("LevelWarning", levelWarning, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }}
                            >
                              Warning
                            </Checkbox>
                          )}
                        />
                        <Controller
                          control={control}
                          name="LevelInformation"
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              inputRef={field.ref}
                              name="LevelInformation"
                              onChange={(e) => {
                                const levelInformation = e.target.checked;

                                setValue("LevelInformation", levelInformation, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }}
                            >
                              Information
                            </Checkbox>
                          )}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider style={{ marginTop: 0 }} />
              <Row>
                <Col span={24}>
                  <SearchableTable
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    buttons={toolbarButtons}
                    rowKey={(r) => r.Id}
                    tableName={tableNames.AppLogs}
                    pageSize={100}
                    size="small"
                    scroll={{ y: 650, x: 935 }}
                  />
                </Col>
              </Row>
            </>
          }
        />

        <Route path="appLogForm/:id" element={<AppLogForm />} />
      </Routes>
    </PermissionWrapper>
  );
};
export default AppLogs;
