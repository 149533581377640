import { Divider, Typography, Spin, Descriptions, Row, Col } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearSelectedAppLog,
  readAppLog,
} from "../../store/appLog/appLogActions";
import { formatDateTime } from "../shared/formatting";
import { BackButton } from "../shared/BackButton";

const { Text } = Typography;

const AppLogForm = () => {
  const { id } = useParams();

  const selected = useSelector((state) => state.appLog.selected);
  const selectedLoading = useSelector((state) => state.appLog.selectedLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readAppLog(id));
  }, [id, dispatch]);

  const onGoBackHandler = () => {
    dispatch(clearSelectedAppLog());
  };
  const summaryItems = [
    {
      key: "1",
      label: "Timestamp",
      children: formatDateTime(selected?.Timestamp),
    },
    {
      key: "2",
      label: "Level",
      children: selected?.Level,
    },
    {
      key: "4",
      label: "User Key",
      children: <Text copyable>{selected?.UserKey}</Text>,
    },
    {
      key: "6",
      label: "Data Primary Key",
      children: <Text copyable>{selected?.DataPrimaryKey}</Text>,
    },
    {
      key: "8",
      label: "Action",
      children: <Text copyable>{selected?.Action}</Text>,
    },
    {
      key: "9",
      label: "Source Context",
      children: <Text copyable>{selected?.SourceContext}</Text>,
    }
  ];

  const detailItems = [
    {
      key: "3",
      label: "Message",
      children: <Text copyable>{selected?.Message}</Text>,
    },
    {
      key: "7",
      label: "Additional Data",
      children: <Text copyable>{selected?.AdditionalData}</Text>,
    },
    {
      key: "10",
      label: "Exception",
      children: <Text copyable>{selected?.Exception}</Text>,
    },
    {
      key: "11",
      label: "Duration",
      children: <Text>{selected?.Duration ? `${selected.Duration} ms` : "N/A"}</Text>,
    },
  ];

  return (
    <Spin spinning={selectedLoading}>
      <Row style={{ paddingBottom: 8 }}>
        <Col style={{ marginRight: 5 }}>
          <BackButton onGoBackHandler={onGoBackHandler} isDirty={false} />
        </Col>
      </Row>
      <Divider style={{ margin: 4 }} />

      <Row>
        <Col span={24}>
          <Descriptions
            layout="horizontal"
            items={summaryItems}
            column={3}
          ></Descriptions>
          <Divider style={{ margin: 4 }} />
          <Descriptions
            layout="vertical"
            items={detailItems}
            column={1}
          ></Descriptions>
        </Col>
      </Row>
    </Spin>
  );
};

export default AppLogForm;
