import {
  // FLUSH,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
  // REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import createRootReducer from "./reducers";

import { configureStore } from "@reduxjs/toolkit";
import Actions from "../store/actor/actorConstants";
import { Actions as AnnouncementActions } from "../store/announcement/announcementConstants";

const CURRENT_REDUX_STORE_VERSION = 114;

const persistConfig = {
  key: "root",
  storage,
  version: CURRENT_REDUX_STORE_VERSION,
  migrate: (state) => {
    if (state != null) {
      if (
        state._persist != null &&
        state._persist.version !== CURRENT_REDUX_STORE_VERSION
      ) {
        console.log("Remove persist root from local storage");
        localStorage.removeItem("persist:root");
        return Promise.resolve({});
      } else if (state._persist == null) {
        console.log(
          "Warning: redux-persist state name seems to have changed. This could cause the cache to malfunction."
        );
      }
    }
    return Promise.resolve(state);
  },
};

const reduxStateSyncConfig = {
  whitelist: [
    Actions.READ_ACTOR,
    Actions.READ_ACTOR_SUCCEEDED,
    Actions.SET_SESSION_EXPIRING,
    AnnouncementActions.ACKNOWLEDGE_ANNOUNCEMENT,
    AnnouncementActions.ACKNOWLEDGE_ANNOUNCEMENT_SUCCEEDED,
    "CLEAR_REDUX_STORE",
  ],
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export default function configuredStore() {
  let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        serializableCheck: false,
        // serializableCheck: {
        //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // },
      }).concat(createStateSyncMiddleware(reduxStateSyncConfig));
      return middleware;
    },
  });

  initMessageListener(store);
  let persistor = persistStore(store, null, () =>
    console.log("Persistor store callback called.")
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      store.replaceReducer(persistedReducer);
    });
  }

  return { store, persistor };
}
